import { getBaseURL } from "../..";

interface TokenExistProps {
  accessToken: string | null;
  refreshToken: string | null;
}
export function tokensExist({
  accessToken,
  refreshToken,
}: TokenExistProps): boolean {
  if (!refreshToken || !accessToken) {
    return false;
  }
  return true;
}

export const handleBackToLogin = () => {
  localStorage.clear();
  window.location.replace("/login");
};

interface RefreshAccessTokenResponse {
  accessToken: string;
}
export async function refreshAccessToken(refreshToken: string) {
  try {
    const response = await fetch(getBaseURL().concat("/auth/refresh"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });
    const data: RefreshAccessTokenResponse = await response.json();
    if (response.ok && data.accessToken) {
      localStorage.setItem("accessToken", data.accessToken);
      return data.accessToken;
    }
    if (!response.ok) {
      handleBackToLogin();
    }
    throw new Error(response.statusText);
  } catch (error) {
    handleBackToLogin();
    throw new Error(String(error));
  }
}
