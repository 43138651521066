import MatomoTracker from "@jonkoops/matomo-tracker";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { ReactNode, useMemo } from "react";

import { DomainEnum, getDomain } from "../utils";

const MATOMO_BASE_URL = "https://matomo.florian-gaboriaud.fr";
const MATOMO_LOCALHOST_SITE_ID = 1;
const MATOMO_PRODUCTION_SITE_ID = 2;

export const MatomoContextProvider = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const domain = getDomain();
  const siteId =
    domain === DomainEnum.LOCALHOST
      ? MATOMO_LOCALHOST_SITE_ID
      : MATOMO_PRODUCTION_SITE_ID;
  const userId = localStorage.getItem("email") ?? "anonymous";

  const instance = useMemo<MatomoTracker>(() => {
    return createInstance({
      urlBase: MATOMO_BASE_URL,
      siteId: Number(siteId),
      userId: userId,
    });
  }, [siteId, userId]);

  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
};
