import { GoogleOAuthProvider } from "@react-oauth/google";
import { PropsWithChildren } from "react";

export default function GoogleOAuthWrapper({
  children,
}: PropsWithChildren<{}>): JSX.Element {
  const GOOGLE_CLIENT_ID =
    "230105741621-pqibu4ak6scktjusec3jvn5dcdt016fg.apps.googleusercontent.com";
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      {children}
    </GoogleOAuthProvider>
  );
}
