import NaturePeopleRoundedIcon from "@mui/icons-material/NaturePeopleRounded";
import NightShelterRoundedIcon from "@mui/icons-material/NightShelterRounded";
import TvRoundedIcon from "@mui/icons-material/TvRounded";
import { Avatar } from "@mui/material";
import { LocationEnum } from "../../__generated__/graphql";
import { switchBackgroundColor } from "./utils";

interface LogoRecordProp {
  location: LocationEnum;
  fontColor?: string;
}
export const LogoRecord = ({ location, fontColor }: LogoRecordProp) => {
  switch (location) {
    case LocationEnum.Bedroom:
      return <NightShelterRoundedIcon style={{ color: fontColor }} />;
    case LocationEnum.LivingRoom:
      return <TvRoundedIcon style={{ color: fontColor }} />;
    case LocationEnum.Outside:
      return <NaturePeopleRoundedIcon style={{ color: fontColor }} />;
  }
};
interface ColoredLogoAvatarProp {
  location: LocationEnum;
  fontColorOnly?: boolean;
}

export const ColoredLocationAvatar = ({
  location,
  fontColorOnly,
}: ColoredLogoAvatarProp) => {
  const color = switchBackgroundColor(location);
  return (
    <Avatar
      sx={{
        backgroundColor: fontColorOnly ? "transparent" : color,
      }}
    >
      <LogoRecord
        location={location}
        fontColor={fontColorOnly ? color : "default"}
      />
    </Avatar>
  );
};
