import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";

export function useTrackPageView(): void {
  const url = window.location.href;

  const { trackPageView, pushInstruction } = useMatomo();

  useEffect(() => {
    trackPageView({
      href: window.location.href,
    });
  }, [trackPageView, pushInstruction, url]);
}
