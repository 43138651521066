import {
  LocationEnum,
  RecordTypeEnum,
  SunPhaseEnum,
} from "../../__generated__/graphql";

export const sunPhaseEmoji = (
  sunPhase: SunPhaseEnum | null | undefined,
  hours: number
) => {
  switch (sunPhase) {
    case SunPhaseEnum.Visible:
      return "☀️  ";
    case SunPhaseEnum.NotVisible:
      return "🌙  ";
    default:
      return `${hours}h  `;
  }
};

export const displayTempOrHumidity = (value: number, type: RecordTypeEnum) => {
  if (type === RecordTypeEnum.Humidity) {
    const val = Math.round(value);
    return val.toLocaleString() + "%";
  } else {
    const val = Math.round(10 * value) / 10;
    return val.toLocaleString() + "°";
  }
};

export const switchBackgroundColor = (location: LocationEnum): string => {
  switch (location) {
    case LocationEnum.Bedroom:
      return "#00afb9";
    case LocationEnum.LivingRoom:
      return "#f25c54";
    case LocationEnum.Outside:
      return "#7DB756";
  }
};

export const sortBySunPhase = (
  a: SunPhaseEnum | null | undefined,
  b: SunPhaseEnum | null | undefined
) => {
  if (!a) return -1;
  else if (!b) return 1;
  else if (a === SunPhaseEnum.Visible && b !== SunPhaseEnum.Visible) return -1;
  else if (a !== SunPhaseEnum.Visible && b === SunPhaseEnum.Visible) return 1;
  return 0;
};
