export enum DomainEnum {
  DOMONTROUGE = "domontrouge",
  BONOTIQUE = "bonotique",
  LOCALHOST = "localhost",
  DOMOSTAGING = "domostaging",
}

export const getDomain = (): DomainEnum => {
  const domain = window.location.hostname.split(".")[0];
  switch (domain.toLowerCase()) {
    case DomainEnum.BONOTIQUE:
      return DomainEnum.BONOTIQUE;
    case DomainEnum.DOMONTROUGE:
      return DomainEnum.DOMONTROUGE;
    case DomainEnum.LOCALHOST:
      return DomainEnum.LOCALHOST;
    case DomainEnum.DOMOSTAGING:
      return DomainEnum.DOMOSTAGING;
    default:
      throw new Error(`Unknown domain ${domain}`);
  }
};
