import { useMatomo } from "@jonkoops/matomo-tracker-react";
import NightlightIcon from "@mui/icons-material/Nightlight";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { Chip } from "@mui/material";
import { sub } from "date-fns";
import { useState } from "react";
import { SunPhaseEnum } from "../__generated__/graphql";
import {
  DateRangeSelectorEvent,
  MatomoCategory,
} from "../context/matomo/types";
interface Props {
  setRange: React.Dispatch<React.SetStateAction<[Date, Date] | null>>;
  label: DateRangeSelectorEvent;
  sunPhase?: SunPhaseEnum | null;
  setSunPhase?: React.Dispatch<React.SetStateAction<SunPhaseEnum | null>>;
}

export const ShortcutChips = (props: Props) => {
  const { setRange, sunPhase, setSunPhase } = props;
  const [label, setLabel] = useState<DateRangeSelectorEvent>(props.label);
  const { trackEvent } = useMatomo();
  const today = new Date();
  const shortcutsItems: {
    label: string;
    value: [Date, Date];
    matomoEvent: DateRangeSelectorEvent;
  }[] = [
    {
      label: "24h",
      value: [sub(today, { hours: 24 }), today],
      matomoEvent: DateRangeSelectorEvent.H24,
    },
    {
      label: "72h",
      value: [sub(today, { hours: 72 }), today],
      matomoEvent: DateRangeSelectorEvent.H72,
    },
    {
      label: "7 jours",
      value: [sub(today, { days: 7 }), today],
      matomoEvent: DateRangeSelectorEvent.D7,
    },

    {
      label: "1 mois",
      value: [sub(today, { months: 1 }), today],
      matomoEvent: DateRangeSelectorEvent.M1,
    },
    {
      label: "3 mois",
      value: [sub(today, { months: 3 }), today],
      matomoEvent: DateRangeSelectorEvent.M3,
    },
    {
      label: "6 mois",
      value: [sub(today, { months: 6 }), today],
      matomoEvent: DateRangeSelectorEvent.M6,
    },
    {
      label: "1 an",
      value: [sub(today, { months: 12 }), today],
      matomoEvent: DateRangeSelectorEvent.Y1,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        overflowX: "auto",
        whiteSpace: "nowrap",
        margin: "2px",
        padding: "2px",
      }}
    >
      {setSunPhase &&
        Object.values(SunPhaseEnum)
          .sort((a, b) => b.localeCompare(a))
          .map((item) => (
            <Chip
              key={item}
              label={item === SunPhaseEnum.Visible ? "Jour" : "Nuit"}
              color={sunPhase === item ? "primary" : "default"}
              icon={
                item === SunPhaseEnum.Visible ? (
                  <WbSunnyIcon />
                ) : (
                  <NightlightIcon />
                )
              }
              onClick={() => {
                setSunPhase(sunPhase === item ? null : item);

                trackEvent({
                  category: MatomoCategory.MOMENT_SELECTOR,
                  action: item,
                  // 0 if disabled, 1 if enabled
                  value: sunPhase === item ? 0 : 1,
                });
              }}
              sx={{ m: "2px" }}
            />
          ))}
      {shortcutsItems.map((item) => (
        <Chip
          key={item.label}
          label={item.label}
          onClick={() => {
            setRange(item.value);
            setLabel(item.matomoEvent);
            trackEvent({
              category: MatomoCategory.DATE_RANGE_SHORTCUT,
              action: item.matomoEvent,
            });
          }}
          sx={{ m: "2px" }}
          color={label === item.label ? "primary" : "default"}
        />
      ))}
    </div>
  );
};
