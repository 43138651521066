import { useQuery } from "@apollo/client";
import { ApexOptions } from "apexcharts";
import { differenceInDays, format, startOfDay, subDays } from "date-fns";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GRAPH_WEATHER_QUERY } from "../../GraphQL/queries";
import {
  RecordTypeEnum,
  SunPhaseEnum,
  WeatherHistoryGraphDataQuery,
} from "../../__generated__/graphql";
import { DateRangeSelectorEvent } from "../../context/matomo/types";
import MyDateRangePicker from "../MyDateRangePicker";
import { ShortcutChips } from "../ShortcutChips";

interface Props {
  type: RecordTypeEnum;
  defaultZoom?: [Date, Date];
  showDateRangePicker?: boolean;
  showShortcutChips?: boolean;
  withElectricity?: boolean;
}

export const MyLineGraph = (props: Props) => {
  const defaultZoom: [Date, Date] = props.defaultZoom ?? [
    subDays(new Date(), 1),
    new Date(),
  ];
  const [range, setRange] = useState<[Date, Date] | null>(defaultZoom);
  const [sunPhase, setSunPhase] = useState<SunPhaseEnum | null>(null);

  const myDateTimeFormat = (timestamp: number, range: [Date, Date] | null) => {
    if (range && differenceInDays(range[1], range[0]) > 14) {
      return format(startOfDay(timestamp), "dd/MM");
    }
    const isStartOfDay = timestamp === startOfDay(timestamp).getTime();
    return isStartOfDay
      ? format(new Date(timestamp), "dd/MM")
      : format(new Date(timestamp), "dd/MM HH'h'mm");
  };

  const yAxisOpt = props.withElectricity
    ? [
        {
          seriesName: "Température",
          decimalsInFloat: 0,
        },
        {
          seriesName: "Température",
          decimalsInFloat: 0,
          show: false,
        },
        {
          seriesName: "Température",
          decimalsInFloat: 0,
          show: false,
        },
        { seriesName: "Electricity", decimalsInFloat: 0, opposite: true },
      ]
    : {
        decimalsInFloat: 0,
      };

  const yTooltip = props.withElectricity
    ? [
        {
          formatter: function (val: number) {
            return val?.toFixed(0);
          },
        },
        {
          formatter: function (val: number) {
            return val?.toFixed(props.type === RecordTypeEnum.Humidity ? 0 : 1);
          },
        },
        {
          formatter: function (val: number) {
            return val?.toFixed(props.type === RecordTypeEnum.Humidity ? 0 : 1);
          },
        },
        {
          formatter: function (val: number) {
            return val?.toFixed(props.type === RecordTypeEnum.Humidity ? 0 : 1);
          },
        },
      ]
    : {
        formatter: function (val: number) {
          return val?.toFixed(props.type === RecordTypeEnum.Humidity ? 0 : 1);
        },
      };

  const defaultOptions: ApexOptions = {
    noData: { text: "Chargement ..." },
    plotOptions: {
      bar: {
        columnWidth: "100%",
      },
    },
    chart: {
      id: `${props.type}-${range?.[0]?.getTime()}`, // should be a different one every re-render
      type: "line",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      events: {
        zoomed: (__chartContext, { xaxis }) => {
          setRange([
            xaxis.min
              ? new Date(Math.floor(xaxis.min))
              : subDays(new Date(), 2),
            xaxis.max ? new Date(Math.floor(xaxis.max)) : new Date(),
          ]);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      showForSingleSeries: true,
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    markers: {
      size: 0.4,
      fillOpacity: 0.5,
    },
    yaxis: yAxisOpt,
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value: string) => myDateTimeFormat(Number(value), range),
      },
    },
    tooltip: {
      fillSeriesColor: true,
      onDatasetHover: { highlightDataSeries: true },
      x: {
        formatter: myDateTimeFormat,
      },
      y: yTooltip,
    },
  };

  const res = useQuery(GRAPH_WEATHER_QUERY, {
    variables: {
      input: {
        min: range ? range[0].getTime() : null,
        max: range ? range[1].getTime() : null,
        type: props.type,
        sunPhase,
        withElectricity: props.withElectricity,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  const { data, previousData } = res;
  const toPlot = data ?? previousData;

  return (
    <MyLineGraphComponent
      range={range}
      setRange={setRange}
      sunPhase={sunPhase}
      setSunPhase={setSunPhase}
      defaultOptions={defaultOptions}
      data={toPlot}
      showDateRangePicker={props.showDateRangePicker}
      showShortcutChips={props.showShortcutChips}
      label={DateRangeSelectorEvent.H24}
    />
  );
};

interface PropsInterface {
  range: [Date, Date] | null;
  setRange: React.Dispatch<React.SetStateAction<[Date, Date] | null>>;
  sunPhase: SunPhaseEnum | null;
  setSunPhase: React.Dispatch<React.SetStateAction<SunPhaseEnum | null>>;
  defaultOptions: ApexOptions;
  data: WeatherHistoryGraphDataQuery | undefined;
  showDateRangePicker?: boolean;
  showShortcutChips?: boolean;
  label: DateRangeSelectorEvent;
}
const MyLineGraphComponent = ({
  range,
  setRange,
  sunPhase,
  setSunPhase,
  defaultOptions,
  data,
  showDateRangePicker = true,
  showShortcutChips = true,
  label,
}: PropsInterface) => {
  return (
    <>
      {showDateRangePicker && (
        <MyDateRangePicker range={range} setRange={setRange} />
      )}
      {showShortcutChips && (
        <ShortcutChips
          setRange={setRange}
          setSunPhase={setSunPhase}
          sunPhase={sunPhase}
          label={label}
        />
      )}
      <ReactApexChart
        options={defaultOptions}
        series={data?.weatherHistoryGraphData.series ?? []}
        type="line"
        height={300}
      />
    </>
  );
};
