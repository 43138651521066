import translation from "./translation.json";

interface Translation {
  [key: string]: string;
}

export function translateWeatherDescription(
  key: string | undefined | null
): string | undefined | null {
  if (!key) {
    return key;
  }
  const t: string = (translation as Translation)[key];
  if (!t) {
    return key;
  }
  return t;
}
