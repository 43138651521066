import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { endOfDay, startOfDay } from "date-fns";
import fr from "date-fns/locale/fr";
import React from "react";
import { MatomoCategory } from "../context/matomo/types";

interface Props {
  range: [Date, Date] | null;
  setRange: React.Dispatch<React.SetStateAction<[Date, Date] | null>>;
}
export default function MyDateRangePicker(props: Readonly<Props>) {
  const { trackEvent } = useMatomo();

  const { range, setRange } = props;
  if (!range) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <MobileDateRangePicker
        value={range}
        onAccept={(newValue) => {
          const start = startOfDay(newValue[0] ?? new Date());
          const end = endOfDay(newValue[1] ?? new Date());
          setRange([start, end]);
          trackEvent({
            category: MatomoCategory.DATE_RANGE_PICKER,
            action: "change",
          });
        }}
        calendars={2}
        disableFuture
        localeText={{
          start: "Début",
          end: "Fin",
          cancelButtonLabel: "Annuler",
          toolbarTitle: "",
        }}
      />
    </LocalizationProvider>
  );
}
