import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { LicenseInfo } from "@mui/x-license-pro";
import ReactDOM from "react-dom/client";
import App from "./App";
import { DomainEnum, getDomain } from "./context/utils";
import { handleBackToLogin, refreshAccessToken } from "./pages/login/utils";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

LicenseInfo.setLicenseKey(
  "63cdcff003c86a961f1b47b5703dd5e0Tz0wLEU9MjUzNDA0ODY0MDAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg=="
);
console.log(
  JSON.stringify({
    sha: process.env.REACT_APP_RELEASE_SHA,
    datetime: process.env.REACT_APP_RELEASE_DATETIME,
  })
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const getBaseURL = (): string => {
  const domain = getDomain();
  switch (domain) {
    case DomainEnum.BONOTIQUE:
      return "https://apollo-bonotique.florian-gaboriaud.fr";
    case DomainEnum.DOMONTROUGE:
      return "https://apollo.florian-gaboriaud.fr";
    case DomainEnum.LOCALHOST:
      return "http://localhost:4000";
    case DomainEnum.DOMOSTAGING:
      return "https://apollo-domostaging.florian-gaboriaud.fr";
    default:
      throw new Error(`Unknown domain ${domain}`);
  }
};

const httpLink = createHttpLink({
  uri: getBaseURL().concat("/gql"),
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    console.warn("No access token found, redirecting to login");
    handleBackToLogin();
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        handleBackToLogin();
      } else if (error.statusCode === 401) {
        return refreshAccessToken(refreshToken)
          .then((newToken) => {
            if (!newToken) {
              handleBackToLogin();
              throw new Error("No new token received");
            }

            // Modify the old headers to retry
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${newToken}`,
              },
            }));
            return true;
          })
          .catch((error) => {
            console.error(error);
            handleBackToLogin();
            return false;
          });
      }
      return false;
    },
  },
});

export const myApolloClient = new ApolloClient({
  link: ApolloLink.from([retryLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

root.render(
  <ApolloProvider client={myApolloClient}>
    <App />
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
