import { useMatomo } from "@jonkoops/matomo-tracker-react";
import LoopIcon from "@mui/icons-material/Loop";
import { Fab } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { getBaseURL, myApolloClient } from "..";
import { MatomoCategory } from "../context/matomo/types";

export const MyFab = () => {
  const { trackEvent } = useMatomo();

  const [loading, setLoading] = useState(false);

  const handleRefresh = async () => {
    trackEvent({
      category: MatomoCategory.REFRESH_DATA,
      action: "refresh",
    });

    setLoading(true);
    const accessToken = localStorage.getItem("accessToken");
    await fetch(getBaseURL() + "/refreshData", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async () => {
        await myApolloClient.refetchQueries({
          include: "active",
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <Fab
      color="primary"
      aria-label="refresh"
      size="small"
      sx={{ position: "fixed", top: 4, right: 4 }}
      onClick={async () => {
        await handleRefresh();
      }}
      disabled={loading}
    >
      {loading ? <CircularProgress /> : <LoopIcon />}
    </Fab>
  );
};
