import BoltIcon from "@mui/icons-material/Bolt";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import HomeIcon from "@mui/icons-material/Home";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import { AppBar as AppBarMaterial, Tab, Tabs, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTrackPageView } from "../context/matomo/MatomoTracker";

const useActiveTab = (): string | boolean => {
  const location = useLocation();
  const root = location.pathname.split("/")[1];
  const tab = "/" + root;
  return tab === "/" ? false : tab;
};
const AppBar = (): JSX.Element => {
  useTrackPageView();

  const activeTab = useActiveTab();

  const getTabs = (): JSX.Element[] => {
    const tabs = [
      <Tab
        disableRipple
        key="home"
        component={Link}
        value="/home"
        to="/home"
        icon={<HomeIcon />}
        sx={{ flexGrow: 1 }}
      />,
      <Tab
        disableRipple
        key="temperature"
        component={Link}
        value="/temperature"
        to="/temperature"
        icon={<DeviceThermostatIcon />}
        sx={{ flexGrow: 1, maxHeight: "45px" }}
      />,
      <Tab
        disableRipple
        key="humidity"
        icon={<WaterDropIcon />}
        component={Link}
        value="/humidity"
        to="/humidity"
        sx={{ flexGrow: 1 }}
      />,
      <Tab
        disableRipple
        key="electricity"
        component={Link}
        value="/electricity"
        to="/electricity"
        icon={<BoltIcon />}
        sx={{ flexGrow: 1 }}
      />,
    ];
    return tabs;
  };

  return (
    <AppBarMaterial
      elevation={0}
      position="fixed"
      style={{
        top: "auto",
        bottom: 0,
      }}
      color="inherit"
    >
      <Toolbar>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="inherit"
          sx={{
            flexGrow: 1,
          }}
          centered
          variant="fullWidth"
        >
          {getTabs()}
        </Tabs>
      </Toolbar>
    </AppBarMaterial>
  );
};

export default AppBar;
